import React from "react";
import Headerx from "../components/headerx";
import '../styles/Index.css'; // Import your stylesheetcl


const About = () => {
    return (
        <>
            <Headerx />
            <div className=" container mx-auto mt-6 columns-2 flex">
                <div className="prose prose-neutral ml-11 pl-11">
                    <h4>About Me</h4>
                    <p className="">
                        Greetings! I'm Parth Singh, a fourth-year Computer Science major at Cal Poly Pomona, deeply passionate about the realms of web development and machine learning. Throughout my academic journey, I've immersed myself in a diverse range of courses, honing my skills in Python, Java, and JavaScript, as well as gaining experience in different stacks and frameworks. My journey into machine learning has been equally exciting, with engagement in projects that involve data analysis and predictive modeling, as well as some research experience. What fascinates me most is the intersection of web development and machine learning, and I'm always eager to explore projects that bridge these two domains.
                    </p>
                    <p>
                        Looking ahead, I aspire to contribute to building innovative products that can elevate the digital experience. I'm committed to continuous learning, staying updated on the latest technology developments, and eagerly anticipating the next wave of innovation.                    
                    </p>
                    <p>
                        Beyond coding, you'll find me playing soccer or at the movies. I believe in a holistic approach to life, finding inspiration beyond the screen. Thank you for visiting my portfolio! Feel free to explore my projects and connect with me on <a href="https://www.linkedin.com/in/parth-singh-969b74290/" target='_blank'>linkedin</a>. <br></br>Happy coding!
                    </p>
                </div>
                <div className="justify-center w-3/4 avatar ml-11 pl-11">
                    <div className="w-6/12 h-4/12 mt-10 rounded hover:-translate-y-1 hover:scale-110 "><img src={process.env.PUBLIC_URL + '/linkedinPic.jpg'}></img>
                    </div>
                </div>
            </div></>
    );
};

export default About;